$(document).ready(function(){

	// PARALLAX EFFECT

	$(window).scroll(function() {
		 var e = $(this).scrollTop(),
				 t = $("html").height();
		 curTop = 800 * (e / t) + 0, $("#banners-scroll").css("top", curTop + 0 + "px")
	});

 

	// $('#articles_slider').slick({
	// 	dots: false,
	// 	infinite: true,
	// 	speed: 1000,
	// 	// rtl: true,
	// 	autoplay: true,
	// 	autoplaySpeed: 2000,
	// 	arrows: true,
	// 	slidesToShow: 1
	//   });
	


	//   var dirRtlFlag = false;
    // if ($("html").attr('dir') == 'rtl') {
    // dirRtlFlag = true;
    // }
 
    // $('#articles_slider').slick({
    //     dots: false,
    //     arrows: true,
    //     infinite: true,
    //     speed: 600,
    //     autoplay: true,
    //     autoplaySpeed: 3000,
    //     rtl: dirRtlFlag,
    //     slidesToShow: 3,
    //     slidesToScroll: 1,
    //     // prevArrow: $('.recomendations__prev'),
    //     // nextArrow: $('.recomendations__next'),
    //     responsive: [
    //     {
    //         breakpoint: 1024,
    //         settings: {
    //         slidesToShow: 3,
    //         slidesToScroll: 1,
    //         infinite: true,
    //         }
    //     },
    //     {
    //         breakpoint: 993,
    //         settings: {
    //         slidesToShow: 2,
    //         slidesToScroll: 1
    //         }
    //     },
    //     {
    //         breakpoint: 525,
    //         settings: {
    //         slidesToShow: 1,
    //         slidesToScroll: 1
    //         }
    //     }
    //     // You can unslick at a given breakpoint now by adding:
    //     // settings: "unslick"
    //     // instead of a settings object
    //     ]
    // });



// FAQ

$('.faq_wrapper .faq_area ul li a').on('click', function(e){
	e.preventDefault();
	
	$(this).toggleClass('active');
	$(this).siblings('.text').slideToggle(400);
});

// SLIDERS

if($('.slider_main_block').length){

	$('.slider_main_block').slick({
		 slidesToShow: 1,
		 slidesToScroll: 1,
		 arrows: true,
		 dots:false,
		 rtl:false,
		 autoplay:true,
		 pauseOnHover:false
	 });
}

// var dirRtlFlag = false;
// if ($("html").attr('dir') == 'rtl') {
//   dirRtlFlag = true;
// }
 






$('.bussiness_wrap .slider').slick({
	slidesToShow: 3,
	slidesToScroll: 1,
	arrows: true,
	prevArrow:'.bussiness_wrap .arrow_left',
	nextArrow:'.bussiness_wrap .arrow_right',
	dots:false,
	rtl:true,
	autoplay:true,
	pauseOnHover:false,
	responsive: [
		 {
			 breakpoint: 601,
			 settings: {
				 slidesToShow: 2,
				 slidesToScroll: 1
			 }
		 },
		 {
				breakpoint: 501,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1
				}
			}
	 ]
});

	// SLIDERS

	if($('.slider_main_block').length){

		 $('.slider_main_block').slick({
				slidesToShow: 1,
				slidesToScroll: 1,
				arrows: false,
				dots:false,
				rtl:true,
				autoplay:true,
				pauseOnHover:false
			});
	}

	if($('.product_wrap').length){

		 $('.product_wrap .top_block').slick({
				slidesToShow: 1,
				slidesToScroll: 1,
				rtl:true,
				arrows: false,
				dots:false,
				asNavFor: '.product_wrap .bottom_block'
		 });

		 $('.product_wrap .bottom_block').slick({
				slidesToShow: 3,
				slidesToScroll: 1,
				arrows: false,
				dots:false,
				asNavFor: '.product_wrap .top_block',
				rtl:true,
				focusOnSelect: true,
				responsive: [
					 {
					 breakpoint: 769,
							settings: {
								 slidesToShow: 3,
								 slidesToScroll: 1,
								 rtl:true,
							}
					 }
				]
		 });

	}

 
	// FANCYBOX GALLERY

	if($('[data-fancybox]').length){

		 $('[data-fancybox]').fancybox({
				// Options will go here
		 });
		 
	}


});