$(function() {

  // Side Menu Control
  var myDir = ($("html").attr('dir') == 'rtl' ? 'right' : 'left' );
  var mySlidebars = new $.slidebars();
  $('.mobile-menu-btn').on('click', function() {
      mySlidebars.slidebars.toggle(myDir);
  });

    // SCROLL PAGE EVENT

		var $window = $(window);
		$window.on('load scroll', function() {
			var top = $window.scrollTop();
 
			if (top > 10){
				$('.header').addClass('active');
			}
			else{
				$('.header').removeClass('active');
			}
		});
});
